/* eslint-disable */
import Api from "./Api";
export default {
  async saveDoctor(payload) {
    const response = await Api().post(`api/dashboard/doctor/`, payload);
    return response;
  },
  async getAllDoctor(payload) {
    const response = await Api().get(`api/dashboard/doctor/?search=${payload}`);
    return response;
  },
  async getSingleDoctor(payload) {
    const response = await Api().get(`api/dashboard/doctor/${payload}`);
    return response;
  },
  async setDoctorStatus(payload) {
    const response = await Api().post(`api/dashboard/testimonial/status`, payload);
    return response;
  },
  async deleteDoctor(payload) {
    const response = await Api().post(`api/dashboard/doctor/remove/`, payload);
    return response;
  },

};
