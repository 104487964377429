import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ProtectedRoute from "./protectedRoute";
import Login from "../container/pages/login/Login";
import UsersList from "../container/pages/users/Users";
import RoleList from "../container/pages/role/RoleList";
import Profile from "../container/pages/profile/Profile";
import GenderList from "../container/pages/gender/Gender";
import EditPermissions from "../container/pages/role/EditPermissions";

import Error from "../container/error/Error";
import Home from "../container/pages/home/Home";
import FactList from "../container/pages/common_page/fact/FactList";
import BrandList from "../container/pages/common_page/brand/BrandList";
import CategoryList from "../container/pages/common_page/category/CategoryList";
import BlogList from "../container/pages/common_page/blog/BlogList";
import TestimonialList from "../container/pages/common_page/testimonial/TestimonialList";
import ContactUsList from "../container/pages/common_page/contact_us/ContactUsList";
import DoctorList from "../container/pages/common_page/doctor/Doctor";
import AddDoctor from "../container/pages/common_page/doctor/AddDoctor";
import ServiceList from "../container/pages/common_page/service/ServiceList";
// import AddPriceing from "../container/pages/common_page/priceing/AddPriceing";
import SettingList from "../container/pages/common_page/settings/Setting";
import AddSetting from "../container/pages/common_page/settings/AddSettings";

const RoutesConfig = () => {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/signUp" element={<Login />} />
        <Route path="*" element={<Error />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/" element={<Home />} />
          <Route path="/user" element={<UsersList />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/user-list" element={<UsersList />} />

          <Route path="/role" element={<RoleList />} />
          <Route path="/role">
            <Route index element={<RoleList />} />
            <Route path=":role_id" element={<EditPermissions />} />
          </Route>
          <Route path="/gender" element={<GenderList />} />
          <Route path="/fact" element={<FactList />} />
          <Route path="/brand" element={<BrandList />} />
          <Route path="/article" element={<BlogList />} />
          <Route path="/testimonials" element={<TestimonialList />} />
          <Route path="/contactus" element={<ContactUsList />} />
          <Route path="/service" element={<ServiceList />} />
          <Route path="/category" element={<CategoryList />} />
          {/* <Route path="/add-price/">
            <Route index element={<AddPriceing />} />
            <Route path=":price_id" element={<AddPriceing />} />
          </Route> */}

          <Route path="/doctor" element={<DoctorList />} />
          <Route path="/add-doctor/">
            <Route index element={<AddDoctor />} />
            <Route path=":doctor_id" element={<AddDoctor />} />
          </Route>
          <Route path="/sys-config" element={<SettingList />} />
          <Route path="/add-sys-config/">
            <Route index element={<AddSetting />} />
            <Route path=":setting_id" element={<AddSetting />} />
          </Route>
        </Route>
      </Routes>
    </Router>
  );

};

export default RoutesConfig;
