/* eslint-disable */
import Api from "./Api";
export default {

  async SaveSetting(payload) {
    const response = await Api().post(`api/dashboard/sys-config`,payload);
    return response;
  },

  async getAllSetting(payload) {
    const response = await Api().get(`api/dashboard/sys-config?search=${payload}`);
    return response;
  },
  
  async getSingleSetting(payload) {
    const response = await Api().get(`api/dashboard/sys-config/${payload}`);
    return response;
  },

  async setSettingStatus(payload) {
    const response = await Api().post(`api/dashboard/sys-config/status`, payload);
    return response;
  },

  async deleteSetting(payload) {
    const response = await Api().post(`api/dashboard/sys-config/remove`, payload);
    return response;
  },

};
