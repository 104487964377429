/* eslint-disable */
import Api from "./Api";
export default {

  async SaveService(payload) {
    const response = await Api().post(`api/dashboard/service`,payload);
    return response;
  },

  async getAllService(payload) {
    const response = await Api().get(`api/dashboard/service?search=${payload}`);
    return response;
  },
  
  async getSingleService(payload) {
    const response = await Api().get(`api/dashboard/service/${payload}`);
    return response;
  },

  async setServiceStatus(payload) {
    const response = await Api().post(`api/dashboard/service/status`, payload);
    return response;
  },

  async deleteService(payload) {
    const response = await Api().post(`api/dashboard/service/remove`, payload);
    return response;
  },

};
