import * as Yup from "yup";
export const serviceSchema = Yup.object({
  serv_name: Yup.string().min(3).max(100).required("Please Enter the Service Name").nullable().typeError('Please Enter the Service Name'),
  // serv_amt: Yup.string()
  //   .min(0)
  //   .required("Please Enter the Service Price").nullable().typeError('Please Enter the Service Price'),
  // serv_description: Yup.string().required("please Enter the Service Desc"),
  // serv_currency: Yup.string().min(1).max(10).required("please Enter the Service Currency"),
});
export const doctorSchema = Yup.object({
  doctor_name: Yup.string().min(3).max(100).required("Please Enter the Doctor Name").nullable().typeError('Please Enter the Doctor Name')
  ,
 
  // doctor_name: Yup.string().min(1).max(100).required("Please Enter the Doctor Username").nullable().typeError('Please Enter the Doctor Username'),
  // doctor_email: Yup.string().min(2).max(100).required("Please Enter the email").nullable().typeError('Please Enter the email'),
  // doctor_mobile: Yup.string()
  //   .min(10)
  //   .max(10)
  //   .required("Please Enter the Mobile Number").nullable().typeError('Please Enter the Mobile Number'),
  //   doctor_description: Yup.string().required("please Enter the Doctor Description"),
  // doctor_dob: Yup.date()
  //   .nullable()
  //   .required('Please Enter the Date Of Birth').typeError('Please Enter the Date Of Birth'),
  // doctor_designation: Yup.string().min(1).max(100).required("Please Enter the Doctor Designation").nullable().typeError('Please Enter the Doctor Designation'),
});
export const settingSchema = Yup.object({
  setting_hero_head: Yup.string().min(3).max(100).required("Please Enter the Hero Head Title").nullable().typeError('Please Enter the Hero Head Title'),
 
  setting_about_head: Yup.string().min(1).max(100).required("Please Enter the About Head Title").nullable().typeError('Please Enter the About Head Title'),
});
export const contactUsSchema = Yup.object({
  conus_name: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the User Name"),
  conus_email: Yup.string().email().required("Please Enter the Email address"),
  conus_mobile: Yup.string()
    .min(10)
    .max(10)
    .required("Please Enter the Mobile Number"),
  // conus_question: Yup.string().required("please Enter User Designation"),
  // conus_description: Yup.string().required("please Enter Testimonial Comment"),
});
export const testimonialSchema = Yup.object({
  tn_username: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the Testimonial User Name"),
  // tn_designation: Yup.string().required("please Enter User Designation"),
  // tn_comment: Yup.string().required("please Enter Testimonial Comment"),
  // tn_star_rating: Yup.string().required("please Enter Star Rating"),
});
export const catSchema = Yup.object({
  cat_name: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the Category Name"),
});
export const brandSchema = Yup.object({
  brand_name: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the Brand Title"),
  // brand_image: Yup.mixed().test(
  //   "brand_image",
  //   "Please Choose a Brand Image",
  //   function (value) {
  //     const bannerPath = this.resolve(Yup.ref("brand_image")); // Replace with actual path field name
  //     if (!bannerPath && !value) {
  //       return this.createError({
  //         message: "Brand Image is required",
  //         path: "brand_image",
  //       });
  //     }

  //     return true;
  //   }
  // ),
  // brand_description: Yup.string().required("please Enter Brand Description"),
  // brand_is_position: Yup.number()
  //   .min(1)
  //   .max(999)
  //   .required("Please Enter the Brand Position"),
});
export const factSchema = Yup.object({
  fact_head: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the Faq Title"),
  // fact_name: Yup.string()
  //   .min(2)
  //   .max(25)
  //   .required("Please Enter the Faq Title"),
  // fact_description: Yup.string().required("please Enter FAQ Description"),
  // fact_is_position: Yup.number()
  //   .min(1)
  //   .max(999)
  //   .required("Please Enter the FAQ Position"),
});
export const blogSchema = Yup.object({
  blog_title: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the Blog Title"),
  // blog_content: Yup.string().required("please Enter Blog Content"),
  // blog_keyword: Yup.string().required("please Enter Blog Keyword"),
  // blog_short_desc: Yup.string().required("please Enter Blog Short Description"),
  // blog_long_desc: Yup.string().required("please Enter Blog Long Description"),
  // blog_is_published: Yup.boolean().required("Please Enter the FAQ Position"),
  // blog_banner: Yup.mixed().test(
  //   "blog_banner",
  //   "Please Choose a Blog Banner",
  //   function (value) {
  //     const bannerPath = this.resolve(Yup.ref("blog_banner")); // Replace with actual path field name
  //     if (!bannerPath && !value) {
  //       return this.createError({
  //         message: "Blog Banner is required",
  //         path: "blog_banner",
  //       });
  //     }

  //     return true;
  //   }
  // ),
});
export const userInfoSchema = Yup.object({
  user_first_name: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the First Name"),
  user_last_name: Yup.string()
    .min(2)
    .max(100)
    .required("please Enter the Last Name"),
  user_email: Yup.string().min(2).max(100).required("please Enter the email"),
});
export const genderSchema = Yup.object({
  gender_name_en: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the Engllish gender"),
  gender_name_ar: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the Arabic gender"),
});
export const stateSchema = Yup.object({
  state_name_en: Yup.string()
    .min(2)
    .max(40)
    .required("Please Enter English State Name"),
  state_name_ar: Yup.string()
    .min(2)
    .max(40)
    .required("Please Enter Arabic State Name"),
  fk_country_id: Yup.string().required("Please select country"),
});
export const citySchema = Yup.object({
  city_name_en: Yup.string()
    .min(2)
    .max(40)
    .required("Please Enter English City Name"),
  city_name_ar: Yup.string()
    .min(2)
    .max(40)
    .required("Please Enter Arabic City Name"),
  fk_state_id: Yup.string().required("Please select state"),
});

export const roleSchema = Yup.object({
  role_name: Yup.string().min(2).max(50).required("Please Enter the Role Name"),
});

export const userSchema = Yup.object({
  user_first_name: Yup.string()
    .min(3)
    .max(25)
    .required("Please Enter the First Name"),
  user_last_name: Yup.string()
    .min(2)
    .max(25)
    .required("Please Enter the Last Name"),
  user_email: Yup.string().email().required("Please Enter the Email address"),
  user_mobile: Yup.string()
    .min(10)
    .max(10)
    .required("Please Enter the Mobile Number"),
  user_password: Yup.string().min(6).required("Please Enter the Password"),
  // conf_password: Yup.string()
  // 	.required()
  // 	.oneOf([Yup.ref("user_password"), null], "Password must match"),
  // user_location_id: Yup.string().required("Please Enter the Location ID"),
  user_role: Yup.string().required("Please Enter the Role"),
});

export const profileSchema = Yup.object({
  user_password: Yup.string().min(6).nullable().required("Please Enter the Password"),
  conf_password: Yup.string().nullable()
    .required()
    .oneOf([Yup.ref("user_password"), null], "Password must match"),
});



