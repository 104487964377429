
import React, { useEffect, useState, Fragment } from "react";

import { Link, useNavigate } from "react-router-dom";
import { Dialog, Menu, Transition } from '@headlessui/react'
import { useFormik } from "formik";
import { accountSchema } from "../../../schemas";
import empty from "../../../assets/images/empty.png"
import moment from "moment";
import { getAllowedActions, checkPermissionsAndRedirect, capitalizeFirstLetter } from "../../../helper/commonHelper";
import {
  ArrowDownCircleIcon,
  ArrowPathIcon,
  ArrowUpCircleIcon,
  Bars3Icon,
  EllipsisHorizontalIcon,
  PlusSmallIcon,
  ArrowDownIcon,
  ArrowUpIcon
} from '@heroicons/react/20/solid'
import {
  BellIcon, XMarkIcon,
  TvIcon,
  UsersIcon,
  UserMinusIcon,
  CircleStackIcon,
  ComputerDesktopIcon,
  ArrowTrendingUpIcon,

} from '@heroicons/react/24/outline'
import MasterServices from "../../../ApiServices/MasterServices";
import { toast } from "react-toast";
import makeAnimated from 'react-select/animated';
import FSLoader from "../../../components/FSLoader";
import { FallingLinesLoader } from "../../../components/spinners/Spinner";



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function userStatusToggle(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Home = () => {
  let hasActiveTrades = false;


  const navigate = useNavigate();
  const [allowedActions, setAllowedActions] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [isFSLoading, setSFLoading] = useState(false);
  const [dashCounts, setDashCounts] = useState([]);

  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (permissions) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect('/account', permissions, navigate);
      const actions = getAllowedActions(permissions, '/account');
      setAllowedActions(actions);
    }
  }, []);

  useEffect(() => {
    getAllCommonData();
  }, []);


  const getAllCommonData = async () => {
    try {
      const res = await MasterServices.getAllDashDataCount()
      if (res.status) {
        setIsLoading(false);
        let stats = [];
        if (res?.data?.data) {
          let total = res?.data?.data;

          if (total?.user) {
            stats.push({ name: 'Users', total: total?.user, change: UsersIcon, changeType: 'positive' })
          }
          if (total?.doctor) {
            stats.push({ name: 'Doctors', total: total?.doctor, change: UsersIcon, changeType: 'positive' })
          }
          if (total?.blog) {
            stats.push({ name: 'Articles', total: total?.blog, change: UsersIcon, changeType: 'positive' })
          }
          if (total?.fact) {
            stats.push({ name: 'Facts', total: total?.fact, change: UsersIcon, changeType: 'positive' })
          }
          if (total?.service) {
            stats.push({ name: 'Services', total: total?.service, change: UsersIcon, changeType: 'positive' })
          }
          if (total?.testimonial) {
            stats.push({ name: 'Testimonial', total: total?.testimonial, change: UsersIcon, changeType: 'positive' })
          }
          if (total?.contact) {
            stats.push({ name: 'Contact US', total: total?.contact, change: UsersIcon, changeType: 'positive' })
          }
          setDashCounts(stats);

        }
      }
    } catch (e) {
      console.log(e, "error in getAllCommonData")
      toast.error("Fatal Error")
    }
  }

  return (
    <>
      {isLoading ? (
        <FallingLinesLoader />
      ) : (<main>

        <FSLoader isLoading={isFSLoading} title="Processing..." />

        <div className="relative isolate overflow-hidden bg-white border rounded-md">
          {/* Secondary navigation */}
          <header className="pb-4 pt-6 sm:pb-6">
            <div className="mx-auto flex max-w-7xl flex-wrap items-center gap-6 px-4 sm:flex-nowrap sm:px-6 lg:px-8">
              <h1 className="text-base font-semibold leading-7 text-gray-900">Statistics</h1>
              {/* <div className="order-last flex w-full gap-x-8 text-sm font-semibold leading-6 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">

                <a className='text-[#0172fd]'>
                  All Time
                </a>
              </div> */}

              <div className="ml-auto flex items-center gap-x-4">


              </div>
            </div>
          </header>

          {/* Stats */}
          <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
            <dl className="mx-auto grid max-w-7xl grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 lg:px-2 xl:px-0">
              {dashCounts?.length > 0 && dashCounts?.map((stat, statIdx) => (
                <div
                  key={stat.name}
                  className={classNames(
                    statIdx % 2 === 1 ? 'sm:border-l' : statIdx === 2 ? 'lg:border-l' : '',
                    'flex items-baseline flex-wrap justify-between gap-y-2 gap-x-4 border-t border-gray-900/5 px-4 py-10 sm:px-6 lg:border-t-0 xl:px-8'
                  )}
                >
                  <dt className="text-sm font-medium leading-6 text-gray-500">{stat.name}</dt>
                  <dd
                    className={classNames(
                      stat.changeType === 'negative' ? 'text-rose-600' : 'text-gray-700',
                      'text-xs font-medium'
                    )}
                  >
                    <stat.change
                      className="mr-3 h-10 w-10"
                      aria-hidden="true"
                    />

                  </dd>
                  <dd className="w-full flex-none text-4xl font-medium leading-10 tracking-tight text-gray-900">
                    {stat.total}
                  </dd>
                </div>
              ))}
            </dl>
          </div>

          <div
            className="absolute left-0 top-full -z-10 mt-96 origin-top-left translate-y-40 -rotate-90 transform-gpu opacity-20 blur-3xl sm:left-1/2 sm:-ml-96 sm:-mt-10 sm:translate-y-0 sm:rotate-0 sm:transform-gpu sm:opacity-50"
            aria-hidden="true"
          >
            <div
              className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#FF80B5] to-[#9089FC]"
              style={{
                clipPath:
                  'polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)',
              }}
            />
          </div>
        </div>

        {/* <div className="space-y-16 py-6 my-5 xl:space-y-5 border rounded-md bg-white"> */}
          {/* Recent activity table */}
          {/* <div> */}
            {/* <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <div className="flex align-middle items-center gap-2">
                <h2 className="mx-auto max-w-2xl text-base font-semibold leading-6 text-gray-900 lg:mx-0 lg:max-w-none mr-2">
                  Connected Accounts
                </h2>
                <select className="rounded-md text-sm" onChange={(e) => {
                  handleConnectChange(e);
                }}>
                  {connections.map((data, index) => (
                    <option key={index} value={data.value}>{data.label}</option>
                  ))}
                </select>
                <SelectField fields={fields} onChange={handleFieldChange} />
                <select className="rounded-md text-sm" onChange={(e) => {
                  setFieldsOrder(e.target.value);
                  handleChangeOrder(e.target.value);
                }}>
                  <option value="">Select Order</option>
                  <option value={'asc'}>ASC</option>
                  <option value={'desc'}>DESC</option>

                </select>
                <input
                  value={accountNo}
                  type="text"
                  placeholder="Search Account No"
                  name="accountNo"
                  autoComplete="off"
                  className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                  onChange={(e) => { handleChangeAccountNo(e.target.value) }}
                />
              </div>

            </div> */}
            {/* <div className="mt-6 overflow-hidden border-t border-gray-100">
              <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">



                </div>
              </div>
            </div> */}
          {/* </div> */}

          {/* {!isConectionLoading ? (

            <div className="mx-auto max-w-7xl px-4 sm:px-4 lg:px-8">
              <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
                <div className="flex items-center justify-between">
                  <h2 className="text-base font-semibold leading-7 text-[#0172fd]">{selectedTimeLabel}</h2>
                </div>

                {accountList.length > 0 ? (

                  <ul role="list" className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">

                    {(accountList.length > 0 && accountList?.map((client, index) => {
                      return (

                        <li key={client.lam_id} className="overflow-hidden rounded-xl border border-gray-200 cursor-pointer"
                          onClick={(e) => {
                            navigate("/accm?uid=" + client?.license_account_map_relation?.master_whop_user_relation.wp_user_id + "&name=" + client?.license_account_map_relation?.master_whop_user_relation.user_name + "&accid=" + client.mua_acc_login);
                          }}
                        >
                          <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                            <img
                              src={`https://cdn-icons-png.flaticon.com/512/3899/3899618.png`}
                              alt={client?.license_account_map_relation?.master_whop_user_relation?.user_name}
                              className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
                            />
                            <div className="text-sm font-medium leading-6 text-gray-900">{client?.license_account_map_relation?.master_whop_user_relation?.user_name}</div>
                            <div as="div" className="relative ml-auto">
                              <div className={`${(selectedTime == 60) ? 'text-green-700 bg-green-50 ring-green-600/20' : (selectedTime == 24) ? 'text-orange-700 bg-orange-50 ring-orange-600/20' : 'text-gray-700 bg-gray-50 ring-gray-600/20'} rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset`} >
                                {(selectedTime == 60) ? 'Active' : (selectedTime == 24) ? 'Stoped' : "In-Active"}
                              </div>
                            </div>
                          </div>
                          <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                            <div className="flex justify-between gap-x-4 py-3">
                              <dt className="text-gray-500">Account No.</dt>
                              <dd className="text-gray-700">
                                {client?.mua_acc_login}
                              </dd>
                            </div>

                            <div className="flex justify-between gap-x-4 py-3">
                              <dt className="text-gray-500">Balance</dt>
                              <dd className="text-gray-700">
                                {client?.mua_acc_balance ? client?.mua_acc_balance : "N/A"} $
                              </dd>
                            </div>

                            <div className="flex justify-between gap-x-4 py-3">
                              <dt className="text-gray-500">Today's PNL</dt>
                              <dd className={classNames(
                                (parseInt(client?.mua_acc_real_pnl) > 0) ? 'bg-green-100 text-green-800' : (parseInt(client?.mua_acc_real_pnl) < 0) ? 'bg-red-100 text-red-800' : 'bg-gray-100 text-gray-600',
                                'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0'
                              )}>
                                {(parseInt(client?.mua_acc_real_pnl) > 0) ? (
                                  <ArrowUpIcon
                                    className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <ArrowDownIcon
                                    className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                                    aria-hidden="true"
                                  />
                                )}
                                {client?.mua_acc_real_pnl ? client?.mua_acc_real_pnl : "N/A"}
                              </dd>
                            </div>

                            <div className="flex justify-between gap-x-4 py-3">
                              <dt className="text-gray-500">Total PNL</dt>
                              <dd
                                className={classNames(
                                  (parseInt(client?.total_pnl) > 0) ? 'bg-green-100 text-green-800' : (parseInt(client?.total_pnl) < 0) ? 'bg-red-100 text-red-800' : 'bg-gray-100 text-gray-600',
                                  'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0'
                                )}
                              >
                                {(parseInt(client?.total_pnl) > 0) ? (
                                  <ArrowUpIcon
                                    className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <ArrowDownIcon
                                    className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                                    aria-hidden="true"
                                  />
                                )}
                                {client?.total_pnl ? client?.total_pnl : "N/A"}
                              </dd>
                            </div>

                            <div className="flex justify-between gap-x-4 py-3">
                              <dt className="text-gray-500">PNL</dt>
                              <dd className="flex items-start gap-x-2">
                                <div
                                  className={classNames(
                                    (parseInt(client?.mua_acc_unreal_pnl) > 0) ? 'bg-green-100 text-green-800' : (parseInt(client?.mua_acc_unreal_pnl) < 0) ? 'bg-red-100 text-red-800' : 'bg-gray-100 text-gray-600',
                                    'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0'
                                  )}
                                >
                                  {(parseInt(client?.mua_acc_unreal_pnl) > 0) ? (
                                    <ArrowUpIcon
                                      className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <ArrowDownIcon
                                      className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                                      aria-hidden="true"
                                    />
                                  )}

                                  <span className="sr-only"> {(parseInt(client?.mua_acc_unreal_pnl) > 0) ? 'Increased' : 'Decreased'} by </span>
                                  {client?.mua_acc_unreal_pnl}
                                </div>
                              </dd>
                            </div>


                            <div className="flex justify-between gap-x-4 py-3">
                              <dt className="text-gray-500">Broker</dt>
                              <dd className="flex items-start gap-x-2">
                                <div className="font-medium text-gray-500">
                                  {client?.mua_acc_server ? client?.mua_acc_server : 'N/A'}
                                </div>
                              </dd>
                            </div>

                            <div className="flex justify-between gap-x-4 py-3">
                              <dt className="text-gray-500">Drawdown %</dt>
                              <dd className="flex items-start gap-x-2">
                                <div className={classNames(
                                  (parseInt(client?.current_dd) > 0) ? 'bg-green-100 text-green-800' : (parseInt(client?.current_dd) <= 0) ? 'bg-red-100 text-red-800' : 'bg-gray-100 text-gray-600',
                                  'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0'
                                )}>
                                  {(parseInt(client?.current_dd) > 0) ? (
                                    <ArrowUpIcon
                                      className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-green-500"
                                      aria-hidden="true"
                                    />
                                  ) : (
                                    <ArrowDownIcon
                                      className="-ml-1 mr-0.5 h-5 w-5 flex-shrink-0 self-center text-red-500"
                                      aria-hidden="true"
                                    />
                                  )}
                                  {client?.current_dd ? client?.current_dd + '%' : 'N/A'}
                                </div>
                              </dd>
                            </div>

                            <div className="flex justify-between gap-x-4 py-3">
                              <dt className="text-gray-500">Open Positions</dt>
                              <dd className="flex items-start gap-x-2">
                                <div className="font-medium text-gray-500">
                                  {client?.mua_acc_positions ? client?.mua_acc_positions : 'N/A'}
                                </div>
                              </dd>
                            </div>

                            <div className="flex justify-between gap-x-4 py-3">
                              <dt className="text-gray-500">Risk Level</dt>
                              <dd className="flex items-start gap-x-2">
                                <div className={classNames(
                                  (client?.mua_acc_risksettings == 'High Risk') ? 'bg-red-100 text-red-800' : (client?.mua_acc_risksettings == 'Low Risk') ? 'bg-green-100 text-green-800' : (client?.mua_acc_risksettings == 'Medium Risk') ? 'bg-orange-100 text-orange-800' : 'bg-gray-100 text-gray-600',
                                  'inline-flex items-baseline rounded-full px-2.5 py-0.5 text-sm font-medium md:mt-2 lg:mt-0'
                                )}>
                                  {client?.mua_acc_risksettings ? client?.mua_acc_risksettings : 'N/A'}
                                </div>
                              </dd>
                            </div>

                            <div className="flex justify-between gap-x-4 py-3">
                              <dt className="text-gray-500">Last Active</dt>
                              <dd className="flex items-start gap-x-2">
                                <div className="font-medium text-gray-500">
                                  {client?.mua_org_timestamp ? client?.mua_org_timestamp : ''} GMT
                                </div>
                              </dd>
                            </div>

                          </dl>
                        </li>

                      );

                    }))}

                  </ul>

                ) : (
                  <div className="border rounded-md p-4 my-4 text-red-500 w-full flex flex-col text-center items-center justify-between">
                    <img src={empty} alt="empty" width="40%" className="text-center items-center justify-between" />
                    No Active Data.
                  </div>

                )
                }

              </div>
            </div>

          ) : (

            <ConnectedAccLoader items={3} />

          )} */}

          {/* Last 60 minutes > && < 24 Hours data */}
          {/* <div className="mx-auto max-w-7xl px-4 sm:px-4 lg:px-8 d-none hidden hide">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
              <div className="flex items-center justify-between">
                <h2 className="text-base font-semibold leading-7 text-[#ff9747]">Last 24 Hours</h2>
                <a href="#" className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
                  View all<span className="sr-only">, doctors</span>
                </a>
              </div>
              <ul role="list" className="mt-6 grid grid-cols-1 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8">
                {currentConnection?.data?.map((client) => (
                  <li key={client.id} className="overflow-hidden rounded-xl border border-gray-200">
                    <div className="flex items-center gap-x-4 border-b border-gray-900/5 bg-gray-50 p-6">
                      <img
                        src={client.imageUrl}
                        alt={client.name}
                        className="h-12 w-12 flex-none rounded-lg bg-white object-cover ring-1 ring-gray-900/10"
                      />
                      <div className="text-sm font-medium leading-6 text-gray-900">{client.name}</div>
                      <div as="div" className="relative ml-auto">
                        <div className="text-green-700 bg-green-50 ring-green-600/20 rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset" >
                          Active
                        </div>
                      </div>
                    </div>
                    <dl className="-my-3 divide-y divide-gray-100 px-6 py-4 text-sm leading-6">
                      <div className="flex justify-between gap-x-4 py-3">
                        <dt className="text-gray-500">Account No.</dt>
                        <dd className="text-gray-700">
                          123456
                        </dd>
                      </div>
                      <div className="flex justify-between gap-x-4 py-3">
                        <dt className="text-gray-500">Balance</dt>
                        <dd className="flex items-start gap-x-2">
                          <div className="font-medium text-gray-900">$199.00</div>
                        </dd>
                      </div>
                      <div className="flex justify-between gap-x-4 py-3">
                        <dt className="text-gray-500">Last Active</dt>
                        <dd className="flex items-start gap-x-2">
                          <div className="font-medium text-gray-900">12:45 GMT</div>
                        </dd>
                      </div>
                    </dl>
                  </li>
                ))}
              </ul>
            </div>
          </div> */}
          {/* 
          <div>
            <Transition.Root show={modalOpenFlage} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-10"
                onClose={setmodalOpenFlage}
              >
                <div className="fixed inset-0" />
                <div className="fixed inset-0 overflow-hidden">
                  <div className="absolute inset-0 overflow-hidden">
                    <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                      <Transition.Child
                        as={Fragment}
                        enter="transform transition ease-in-out duration-500 sm:duration-700"
                        enterFrom="translate-x-full"
                        enterTo="translate-x-0"
                        leave="transform transition ease-in-out duration-500 sm:duration-700"
                        leaveFrom="translate-x-0"
                        leaveTo="translate-x-full"
                      >
                        <Dialog.Panel className="pointer-events-auto w-screen max-w-xs">
                          <form
                            onSubmit={(e) => {
                              e.preventDefault();
                              handleSubmit(e);
                            }}
                            className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl"
                          >
                            <div className="h-0 flex-1 overflow-y-auto">
                              <div className="bg-gradient-to-b from-[#2e2e34] from-10% via-gray-900 via-9%    to-gray-900 to-80%  py-6 px-4 sm:px-6">
                                <div className="flex items-center justify-between">
                                  <Dialog.Title className="text-lg font-medium text-white">
                                    {type == "Edit" ? "Update" : "Add"} Account
                                  </Dialog.Title>
                                  <div className="ml-3 flex h-7 items-center">
                                    <button
                                      type="button"
                                      className="rounded-md  text-cyan-200 hover:text-white"
                                      onClick={() => setmodalOpenFlage(false)}
                                    >
                                      <span className="sr-only">Close panel</span>
                                      <XMarkIcon
                                        className="h-6 w-6"
                                        aria-hidden="true"
                                      />
                                    </button>
                                  </div>
                                </div>
                              </div>

                              <div className="flex flex-1 flex-col justify-between">
                                <div className="p-4 sm:p-6">


                                  <div>
                                    <label
                                      htmlFor="user_name"
                                      className="block text-sm font-medium text-gray-900 mb-1"
                                    >
                                      User Email
                                      <span className="text-red-600">*</span>
                                    </label>
                                  </div>
                                  <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                    <Select
                                      name="user_name"
                                      id="wp_user_id"
                                      // menuPlacement="top"
                                      placeholder={
                                        <span>
                                          Select User Email
                                          <span className="text-red-600">*</span>
                                        </span>
                                      }
                                      value={selectedUserId}
                                      onChange={(e) => {
                                        console.log(e);
                                        values.fk_wp_user_id = e.value;
                                        values.user_name = e.user_name;
                                        getAllProduct(e.value);
                                        getAllPreviousAccount(e.value);
                                        setSelectedUserId(e);
                                        getAllLicense(e?.value);
                                      }}
                                      components={animatedComponents}
                                      options={
                                        userList ? userList
                                          : []
                                      }
                                      classNamePrefix="select"
                                      className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                    />

                                    {errors.fk_wp_user_id && touched.fk_wp_user_id ? (
                                      <div className="text-sm text-red-600">
                                        {errors.fk_wp_user_id.replace("fk_wp_user_id", "User")}
                                      </div>
                                    ) : null}
                                  </div>
                                  <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">
                                    <div>
                                      <label
                                        htmlFor="user_name"
                                        className="block text-sm font-medium text-gray-900 mb-1"
                                      >
                                        User Name
                                        <span className="text-red-600">*</span>
                                      </label>
                                    </div>
                                    <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                      <input
                                        disabled={true}
                                        value={values.user_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type="text"
                                        placeholder="User Name"
                                        name="user_name"
                                        autoComplete="off"
                                        className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                      />
                                      {errors.user_name &&
                                        touched.user_name ? (
                                        <p className="text-red-600 text-sm">
                                          {errors.user_name.replace("user_name", "User Name")}
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>
                                  <div>
                                    <label
                                      htmlFor="fk_wp_prod_id"
                                      className="block text-sm font-medium text-gray-900 mb-1"
                                    >
                                      Select Product
                                      <span className="text-red-600">*</span>
                                    </label>
                                  </div>
                                  <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                    <Select
                                      name="prod_name"
                                      id="wp_prod_id"
                                      menuPlacement="top"
                                      placeholder={
                                        <span>
                                          Select Product
                                          <span className="text-red-600">*</span>
                                        </span>
                                      }
                                      value={selectedProductId}
                                      onChange={(e) => {
                                        setSelectedProductId(e);
                                        console.log(e);
                                        values.fk_wp_prod_id = e.value;
                                        getAllLicense(selectedUserId?.value);
                                        setSelectedLicId({ label: "", value: "" })
                                        setSelectedPreAccountId({ label: "", value: "" })
                                      }}
                                      components={animatedComponents}
                                      options={
                                        productList ? productList
                                          : []
                                      }
                                      classNamePrefix="select"
                                      className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                    />

                                    {errors.fk_wp_prod_id && touched.fk_wp_prod_id ? (
                                      <div className="text-sm text-red-600">
                                        {errors.fk_wp_prod_id.replace("fk_wp_prod_id", "Product")}
                                      </div>
                                    ) : null}
                                  </div>
                                  <div>
                                    <label
                                      htmlFor="license_key"
                                      className="block text-sm font-medium text-gray-900 mb-1"
                                    >
                                      Select License
                                      <span className="text-red-600">*</span>
                                    </label>
                                  </div>
                                  <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                    <Select
                                      name="license_key"
                                      id="wp_lic_id"
                                      menuPlacement="top"
                                      placeholder={
                                        <span>
                                          Select License
                                          <span className="text-red-600">*</span>
                                        </span>
                                      }
                                      value={selectedLicId}
                                      onChange={(e) => {
                                        console.log(e);
                                        values.fk_wp_lic_id = e.value;
                                        values.prod_name = e?.master_whop_product_relation?.prod_name;
                                        setSelectedLicId(e);
                                      }}
                                      components={animatedComponents}
                                      options={
                                        licenseList ? licenseList
                                          : []
                                      }
                                      classNamePrefix="select"
                                      className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                    />

                                    {errors.fk_wp_lic_id && touched.fk_wp_lic_id ? (
                                      <div className="text-sm text-red-600">
                                        {errors.fk_wp_lic_id.replace("fk_wp_lic_id", "License")}
                                      </div>
                                    ) : null}
                                  </div>

                                  {type == "Edit" ? (<div>
                                    <label
                                      htmlFor="lam_account_no"
                                      className="block text-sm font-medium text-gray-900 mb-1"
                                    >
                                      Select Previous Account
                                      <span className="text-red-600">*</span>
                                    </label>
                                  </div>) : (<></>)}
                                  {type == "Edit" ? (<div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                    <Select
                                      name="lam_account_no"
                                      id="lam_id"
                                      menuPlacement="top"
                                      placeholder={
                                        <span>
                                          Select Previous Account
                                          <span className="text-red-600">*</span>
                                        </span>
                                      }
                                      value={selectedPreAccountId}
                                      onChange={(e) => {
                                        console.log(e);
                                        values.fk_previous_account_id = e.value;
                                        values.lam_account_no = e.label;
                                        values.lam_account_no = type == "Edit" ? "" : values.lam_account_no;
                                        setSelectedPreAccountId(e);
                                      }}
                                      components={animatedComponents}
                                      options={
                                        preAccountList ? preAccountList
                                          : []
                                      }
                                      classNamePrefix="select"
                                      className="basic-multi-select block w-full max-w-lg shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                    />

                                    {errors.fk_wp_lic_id && touched.fk_wp_lic_id ? (
                                      <div className="text-sm text-red-600">
                                        {errors.fk_wp_lic_id.replace("fk_wp_lic_id", "License")}
                                      </div>
                                    ) : null}
                                  </div>) : (<></>)}

                                  <div className="mt-2 mb-2 sm:col-span-2 sm:mt-0">

                                    <div>
                                      <label
                                        htmlFor="lam_account_no"
                                        className="block text-sm font-medium text-gray-900 mb-1"
                                      >
                                        Account No
                                        <span className="text-red-600">*</span>
                                      </label>
                                    </div>
                                    <div className="mt-2 mb-3 sm:col-span-2 sm:mt-0">
                                      <input
                                        value={values.lam_account_no}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        type="text"
                                        placeholder="Account No"
                                        name="lam_account_no"
                                        autoComplete="off"
                                        className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                                      />
                                      {errors.lam_account_no &&
                                        touched.lam_account_no ? (
                                        <p className="text-red-600 text-sm">
                                          {errors.lam_account_no.replace("lam_account_no", "Account No")}
                                        </p>
                                      ) : null}
                                    </div>
                                  </div>

                                  <div className="mt-1 pt-2 pb-2 sm:col-span-2 sm:mt-0">
                                    <div className="basis-1/2 text-red-500 text-sm">
                                      {errorMessage !== "" &&
                                        "Error: " + errorMessage}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="flex flex-shrink-0 justify-end px-4 py-4">
                              <button
                                type="button"
                                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2"
                                onClick={() => {
                                  setmodalOpenFlage(false);
                                }}
                              >
                                Cancel
                              </button>
                              <button
                                disabled={disableSubmitButton}
                                type="submit"
                                className={`ml-4 inline-flex justify-center rounded-md bbt bg-gradient-to-b from-[#11ffdb] to-[#0172fd] py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-cyan-500 focus:ring-offset-2 ${disableSubmitButton
                                  ? "bg-gray-700 hover:bg-gray-700"
                                  : "bg-cyan-700"
                                  }`}
                              >
                                {disableSubmitButton ? (
                                  <FaSpinner color="white" />
                                ) : (
                                  type == "Edit" ? "Update" : "Add"
                                )}
                              </button>
                            </div>
                          </form>
                        </Dialog.Panel>
                      </Transition.Child>
                    </div>
                  </div>
                </div>
              </Dialog>
            </Transition.Root>
          </div> */}
        {/* </div> */}
      </main>)}
    </>
  )
}

export default Home