import React, { useEffect, useState } from 'react'
import Breadcrumb from '../../../../components/Breadcrumb'
import { doctorSchema } from '../../../../schemas';
import Select from 'react-select';
import { useFormik } from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import DoctorServices from '../../../../ApiServices/DoctorServices';
import makeAnimated from "react-select/animated";
import { Switch } from '@headlessui/react'
import { toast } from 'react-toast';
import moment from 'moment';
import { FaSpinner } from "react-icons/fa";
import { FallingLinesLoader } from "../../../../components/spinners/Spinner";
import FileUploadWithPreview from '../../../../components/FileUploadWithPreview'; // Assuming this is your child component
import { doc_BASEURL } from "../../../../helper/constant.js";
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const AddDoctor = () => {
  const pages = [{ title: "Add Doctor", href: "/add-doctor", module_id: 40 }];
  const [selectedGender, setSelectedGender] = useState([])
  const [doctorIsActive, setDoctorIsActive] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const { doctor_id: doctor_id } = useParams()
  const animatedComponents = makeAnimated();
  const navigate = useNavigate()
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [doctor_profile, setDoctorImg] = useState(null);


  const gender = [
    {
      label: "Male",
      value: "Male",
    },
    {
      label: "Female",
      value: "Female",
    }
  ]

  useEffect(() => {
    if (doctor_id) {
      getSingleDoctor(doctor_id)
    }
  }, [])

  let initialValues = {
    doctor_id: null,
    doctor_name: "",
    doctor_email: "",
    doctor_mobile: "",
    doctor_dob: "",
    doctor_profile:"",
    doctor_gender: "",
    doctor_designation: "",
    doctor_description: "",
    doctor_is_active: false,
    doctor_facebook: "",
    doctor_indeed: "",
    doctor_insta: "",
    doctor_twitter: "",
    doctor_youtube: "",
  }
  const [doctorForm, setDoctorForm] = useState(initialValues)

  const getSingleDoctor = (doctor_id) => {
    setIsLoading(true)
    DoctorServices.getSingleDoctor(doctor_id).then((res) => {
      const { data, status } = res;
      if (status) {
        console.log("newDoctor", data.data)
        setSelectedGender([{
          label: data?.data?.doctor_gender,
          value: data?.data?.doctor_gender,
        }]);
        initialValues = {
          doctor_id: parseInt(doctor_id),
          doctor_name: data?.data?.doctor_name,
          doctor_email: data?.data?.doctor_email,
          doctor_gender: {
            label: data?.data?.doctor_gender,
            value: data?.data?.doctor_gender,
          },
         
          doctor_mobile: data?.data?.doctor_mobile,
          doctor_dob: moment(data?.data?.doctor_dob).format("YYYY-MM-DD"),
          doctor_profile:data?.data?.doctor_profile,
          doctor_designation: data?.data?.doctor_designation,
          doctor_is_active: data?.data?.doctor_is_active,
          doctor_description: data?.data?.doctor_description,

          doctor_facebook: data?.data?.doctor_facebook,
          doctor_indeed: data?.data?.doctor_indeed,
          doctor_insta: data?.data?.doctor_insta,
          doctor_twitter: data?.data?.doctor_twitter,
          doctor_youtube: data?.data?.doctor_youtube,
        }
        setDoctorImg(data?.data?.doctor_profile);
        console.log("initialValues", initialValues)
        setDoctorForm(initialValues)
      }
      setIsLoading(false)
    }).catch((error) => {
      console.log(error)
      setIsLoading(false)
    });
  }
  const handleFileUpload = async (files) => {
		try {
			// Simulate file upload or handle it as needed
			// Example: Send files to backend via API
			console.log('Files to upload PS:', files);

			// Set upload status
			//   setUploadStatus('Files uploaded successfully');
			if (files?.flag == 1) {
				setDoctorImg(files?.files?.dynamicName)
				toast.success(files?.message);
			} else {
				toast.error(files.message)
			}
		} catch (error) {
			//   setUploadStatus('Error uploading files');
			console.error('Error uploading files:', error);
		}
	};

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      enableReinitialize: true,
      initialValues: doctorForm,
      validationSchema: doctorSchema,
      onSubmit: async (values, action) => {
        console.log("doctor_dob", values.doctor_dob, errors)
        let body = {
          doctor_id: doctor_id ? doctor_id : null,
          doctor_name: values.doctor_name,
          doctor_email: values.doctor_email,
          doctor_mobile: values.doctor_mobile.toString(),
          doctor_dob: new Date(values.doctor_dob),
          doctor_gender: selectedGender.value ? selectedGender.value : values.doctor_gender.value,
          doctor_designation: values.doctor_designation,
          doctor_is_active: doctorIsActive,
          doctor_description: values.doctor_description,
          doctor_facebook: values.doctor_facebook,
          doctor_indeed: values.doctor_indeed,
          doctor_insta: values.doctor_insta,
          doctor_twitter: values.doctor_twitter,
          doctor_youtube: values.doctor_youtube,
          doctor_profile: doctor_profile,
        };
        if (selectedGender.length <= 0) {
          toast.error("Please Select Gender");
          return false;
        }
        console.log("body", body)
        setTimeout(() => {
          setDisableSubmitButton(false)
        }, 1000)
        setDisableSubmitButton(true)
        const response = await DoctorServices.saveDoctor(body);
        if (response.data.status) {
          action.resetForm();
          setDoctorForm(initialValues);
          toast.success(response.data.message);
          setDisableSubmitButton(false)
          navigate("/doctor");
        } else {
          toast.error(response.data.message);
          setDisableSubmitButton(false)
        }
      },
    });
  console.log("doctor_dob", errors)
  return (
    <>
      {!isLoading ? (<div>
        <Breadcrumb pages={pages} />
        <div className="">
          <h1 className="text-xl font-semibold text-gray-900">Add Doctor</h1>
        </div>
        <div>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit(e);
            }}
          >
            <div className="grid grid-cols-1 md:grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 gap-3 mt-3 lg:w-full w-full">
              <div>
                <label htmlFor="doctor_name" className="block text-sm font-medium leading-6 text-gray-900">
                  Doctor Name<span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    type="text"
                    name="doctor_name"
                    id="doctor_name"
                    value={values.doctor_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                    placeholder="Doctor Name"
                  />
                  {errors.doctor_name && touched.doctor_name ? (
                    <div className="text-sm text-red-600">
                      {errors.doctor_name}
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <label htmlFor="doctor_gender" className="block text-sm font-medium leading-6 text-gray-900">
                  Gender
                </label>
                <div>
                  <Select
                    name="doctor_gender"
                    placeholder={
                      <span>
                        Select Gender
                        <span className="text-red-600">*</span>
                      </span>
                    }
                    onChange={setSelectedGender}
                    value={selectedGender}
                    components={animatedComponents}
                    options={gender}
                    classNamePrefix="select"
                    className="basic-multi-select block w-full max-w-lg rounded-md border-[1px]  shadow-sm  focus:border-red-500 sm:text-sm"
                  />
                </div>
              </div>
              <div>
                <label htmlFor="doctor_email" className="block text-sm font-medium leading-6 text-gray-900">
                  Doctor Email<span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    type="email"
                    name="doctor_email"
                    id="doctor_email"
                    value={values.doctor_email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                    placeholder="Doctor Email"
                  />
                  {errors.doctor_email && touched.doctor_email ? (
                    <div className="text-sm text-red-600">
                      {errors.doctor_email}
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <label htmlFor="doctor_mobile" className="block text-sm font-medium leading-6 text-gray-900">
                  Doctor Mobile<span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    type="number"
                    name="doctor_mobile"
                    id="doctor_mobile"
                    value={values.doctor_mobile}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                    placeholder="Doctor Mobile"
                  />
                  {errors.doctor_mobile && touched.doctor_mobile ? (
                    <div className="text-sm text-red-600">
                      {errors.doctor_mobile}
                    </div>
                  ) : null}
                </div>
              </div>

              <div>
                <label htmlFor="doctor_designation" className="block text-sm font-medium leading-6 text-gray-900">
                  Doctor Designation<span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    type="text"
                    name="doctor_designation"
                    id="doctor_designation"
                    value={values.doctor_designation}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                    placeholder="Doctor Designation"
                  />
                  {errors.doctor_designation && touched.doctor_designation ? (
                    <div className="text-sm text-red-600">
                      {errors.doctor_designation}
                    </div>
                  ) : null}
                </div>
              </div>

              <div>
                <label htmlFor="doctor_profile" className="block text-sm font-medium leading-6 text-gray-900">
                  Doctor Profile<span className="text-red-600">*</span>
                </label>
                <div className="">
                  <FileUploadWithPreview onFileUpload={handleFileUpload} bannerName={doctor_profile} />
                  {doctor_profile ? (<img height={40} width={40} className="preview-image" src={doc_BASEURL + doctor_profile} alt={`preview-${doctor_profile}`} />) : (<></>)}
                  {errors.doctor_profile && touched.doctor_profile ? (
                    <div className="text-sm text-red-600">
                      {errors.doctor_profile}
                    </div>
                  ) : null}
                </div>
              </div>

              <div>
                <label htmlFor="doctor_dob" className="block text-sm font-medium leading-6 text-gray-900">
                  Doctor Dob<span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    type="date"
                    name="doctor_dob"
                    id="doctor_dob"
                    value={values.doctor_dob}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                    placeholder="Start Date"
                  />
                  {errors.doctor_dob && touched.doctor_dob ? (
                    <div className="text-sm text-red-600">
                      {errors.doctor_dob}
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <label htmlFor="doctor_facebook" className="block text-sm font-medium leading-6 text-gray-900">
                  Facebook <span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    type="text"
                    name="doctor_facebook"
                    id="doctor_facebook"
                    value={values.doctor_facebook}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                    placeholder="Facebook"
                  />
                  {errors.doctor_facebook && touched.doctor_facebook ? (
                    <div className="text-sm text-red-600">
                      {errors.doctor_facebook}
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <label htmlFor="doctor_indeed" className="block text-sm font-medium leading-6 text-gray-900">
                  Indeed <span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    type="text"
                    name="doctor_indeed"
                    id="doctor_indeed"
                    value={values.doctor_indeed}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                    placeholder="Indeed"
                  />
                  {errors.doctor_indeed && touched.doctor_indeed ? (
                    <div className="text-sm text-red-600">
                      {errors.doctor_indeed}
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <label htmlFor="doctor_insta" className="block text-sm font-medium leading-6 text-gray-900">
                  Instagram <span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    type="text"
                    name="doctor_insta"
                    id="doctor_insta"
                    value={values.doctor_insta}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                    placeholder="Instagram"
                  />
                  {errors.doctor_insta && touched.doctor_insta ? (
                    <div className="text-sm text-red-600">
                      {errors.doctor_insta}
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <label htmlFor="doctor_twitter" className="block text-sm font-medium leading-6 text-gray-900">
                  Twitter <span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    type="text"
                    name="doctor_twitter"
                    id="doctor_twitter"
                    value={values.doctor_twitter}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                    placeholder="Twitter"
                  />
                  {errors.doctor_twitter && touched.doctor_twitter ? (
                    <div className="text-sm text-red-600">
                      {errors.doctor_twitter}
                    </div>
                  ) : null}
                </div>
              </div>
              <div>
                <label htmlFor="doctor_youtube" className="block text-sm font-medium leading-6 text-gray-900">
                  Youtube <span className="text-red-600">*</span>
                </label>
                <div className="">
                  <input
                    type="text"
                    name="doctor_youtube"
                    id="doctor_youtube"
                    value={values.doctor_youtube}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-[#ed1b24] sm:text-sm sm:leading-6"
                    placeholder="Youtube"
                  />
                  {errors.doctor_youtube && touched.doctor_youtube ? (
                    <div className="text-sm text-red-600">
                      {errors.doctor_youtube}
                    </div>
                  ) : null}
                </div>
              </div>


              <div>
                <label htmlFor="doctor_description" className="block text-sm font-medium leading-6 text-gray-900">
                  Doctor Description<span className="text-red-600">*</span>
                </label>
                <div className="">
                  <textarea
                    rows="5"
                    value={values.doctor_description}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    type="text"
                    placeholder="Enter Description"
                    name="doctor_description"
                    autoComplete="off"
                    className="block w-full max-w-lg rounded-md border-[1px] p-2 border-gray-300 shadow-sm focus:border-[1px] focus:border-cyan-500 sm:max-w-xs sm:text-sm"
                  />
                  {errors.doctor_description &&
                    touched.doctor_description ? (
                    <p className="text-red-600 text-sm">
                      {errors.doctor_description}
                    </p>
                  ) : null}
                </div>
              </div>

              <div>
                <label htmlFor="doctorIsActive" className="block text-sm font-medium leading-6 text-gray-900">
                  Doctor Is Active
                </label>
                <Switch
                  checked={doctorIsActive}
                  onChange={setDoctorIsActive}
                  className={classNames(
                    doctorIsActive ? 'bg-indigo-600' : 'bg-gray-200',
                    'relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2'
                  )}
                >
                  <span className="sr-only">Use setting</span>
                  <span
                    aria-hidden="true"
                    className={classNames(
                      doctorIsActive ? 'translate-x-5' : 'translate-x-0',
                      'pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out'
                    )}
                  />
                </Switch>
              </div>
            </div>
            <div className="mt-6 flex items-center justify-end gap-x-6">
              <button
                disabled={disableSubmitButton}
                type="submit"
                className="inline-flex items-center justify-center rounded-md bbt bg-[#0172fd] px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-auto"
              >
                {doctor_id ? (disableSubmitButton ? <FaSpinner color="gold" /> : "Update") : (disableSubmitButton ? <FaSpinner color="gold" /> : "Save")}
              </button>
            </div>
          </form>
        </div>

      </div>) : (

        <FallingLinesLoader />
      )}</>
  )
}

export default AddDoctor