import React from 'react';
import { FallingLines, RotatingLines, InfinitySpin } from "react-loader-spinner";
import './FSLoader.css'; // You can create a CSS file for styling

const FSLoader = ({ isLoading, title }) => {
  // Function to handle outside click and prevent it when loader is shown
  const handleOutsideClick = (e) => {
    if (isLoading) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <div className={`backdrop-blur-sm loader-overlay ${isLoading ? 'active' : ''}`} onClick={handleOutsideClick}>
      <div className="loader-content">
        <InfinitySpin
            visible={true}
            width="200"
            color="#FFF"
            ariaLabel="infinity-spin-loading"
        />
        <div className="loader-text">{title ? title : 'Loading...'}</div>
      </div>
    </div>
  );
};

export default FSLoader;
