/* eslint-disable */
import Api from "./Api";
export default {

  async SaveBrand(payload) {
    const response = await Api().post(`api/dashboard/brand`,payload);
    return response;
  },

  async getAllBrand(payload) {
    const response = await Api().get(`api/dashboard/brand?search=${payload}`);
    return response;
  },
  
  async getSingleBrand(payload) {
    const response = await Api().get(`api/dashboard/brand/${payload}`);
    return response;
  },

  async setBrandStatus(payload) {
    const response = await Api().post(`api/dashboard/brand/status`, payload);
    return response;
  },

  async deleteBrand(payload) {
    const response = await Api().post(`api/dashboard/brand/remove`, payload);
    return response;
  },

};
