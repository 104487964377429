/* eslint-disable */
import Api from "./Api";
export default {

  async SaveArticleCategory(payload) {
    const response = await Api().post(`api/dashboard/articles_cat`,payload);
    return response;
  },

  async getAllArticleCategory(payload) {
    const response = await Api().get(`api/dashboard/articles_cat?search=${payload}`);
    return response;
  },
  
  async getSingleArticleCategory(payload) {
    const response = await Api().get(`api/dashboard/articles_cat/${payload}`);
    return response;
  },

  async setArticleCategoryStatus(payload) {
    const response = await Api().post(`api/dashboard/articles_cat/status`, payload);
    return response;
  },

  async deleteArticleCategory(payload) {
    const response = await Api().post(`api/dashboard/articles_cat/remove`, payload);
    return response;
  },

};
