/* eslint-disable */
import Api from "./Api";
export default {

  async SaveContactUs(payload) {
    const response = await Api().post(`api/dashboard/contact-us`,payload);
    return response;
  },

  async getAllContactUs(payload) {
    const response = await Api().get(`api/dashboard/contact-us?search=${payload}`);
    return response;
  },
  
  async getSingleContactUs(payload) {
    const response = await Api().get(`api/dashboard/contact-us/${payload}`);
    return response;
  },

  async setContactUsStatus(payload) {
    const response = await Api().post(`api/dashboard/contact-us/status`, payload);
    return response;
  },

  async deleteContactUs(payload) {
    const response = await Api().post(`api/dashboard/contact-us/remove`, payload);
    return response;
  },

};
