import React, { useState } from 'react';
import MasterServices from '../ApiServices/MasterServices';

const FileUploadWithPreview = ({ onFileUpload, bannerName, multiple = false }) => {
    const [files, setFiles] = useState([]);
    const [filePreviews, setFilePreviews] = useState([]);

    const handleFileUpload = async (event) => {
        const selectedFiles = event.target.files;
        const previews = [];

        // Convert FileList to Array and update state for files and previews
        Array.from(selectedFiles).forEach(file => {
            previews.push(URL.createObjectURL(file));
        });

        setFiles(Array.from(selectedFiles));
        setFilePreviews(previews);

        const formData = new FormData();
        for (let i = 0; i < selectedFiles.length; i++) {
            formData.append('images', selectedFiles[i]);
        }
        try {
            let { data, status } = await MasterServices.uploadDoc(formData);
            onFileUpload(data); // Pass files data to parent component
            console.log(data); // Log response from backend
        } catch (error) {
            console.error('Error uploading files:', error);
        }
    };
    const handleRemoveFile = (index) => {
        const updatedFiles = files.filter((_, i) => i !== index);
        const updatedPreviews = filePreviews.filter((_, i) => i !== index);

        setFiles(updatedFiles);
        setFilePreviews(updatedPreviews);
    };
    return (
        <>
            <input type="file" multiple={multiple} onChange={handleFileUpload} />
            {/* {multiple && filePreviews.map((preview, index) => (
                <div key={index} style={{ display: 'flex inline-block', position: 'relative', margin: '5px' }}>
                    <img height={40} width={40} className="preview-image" src={preview} alt={`preview-${index}`} />
                    {multiple && (
                        <button
                            style={{
                                position: 'absolute',
                                top: 0,
                                right: 0,
                                backgroundColor: 'red',
                                color: 'white',
                                border: 'none',
                                borderRadius: '50%',
                                cursor: 'pointer'
                            }}
                            onClick={() => handleRemoveFile(index)}
                        >
                            &times;
                        </button>
                    )}
                </div>
            ))} */}
        </>
    );
};

export default FileUploadWithPreview;
