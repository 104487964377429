/* eslint-disable */
import Api from "./Api";
export default {

  async SaveFact(payload) {
    const response = await Api().post(`api/dashboard/fact`,payload);
    return response;
  },

  async getAllFact(payload) {
    const response = await Api().get(`api/dashboard/fact?search=${payload}`);
    return response;
  },
  
  async getSingleFact(payload) {
    const response = await Api().get(`api/dashboard/fact/${payload}`);
    return response;
  },

  async setFactStatus(payload) {
    const response = await Api().post(`api/dashboard/fact/status`, payload);
    return response;
  },

  async deleteFact(payload) {
    const response = await Api().post(`api/dashboard/fact/remove`, payload);
    return response;
  },

};
