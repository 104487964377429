import React from 'react';
import { doc_BASEURL } from "../../../../helper/constant.js";

const DoctorProfileCard = ({ doctor }) => {
  return (
    <div className="max-w-sm rounded overflow-hidden shadow-lg bg-white p-2 ml-2">
      <div className="flex justify-center">
        <img
          className="w-24 h-24 rounded-full"
          src={doctor.doctor_profile ? doc_BASEURL + doctor.doctor_profile : "https://via.placeholder.com/150"}
          alt="Doctor"
        />
      </div>
      <div className="px-6 py-4 text-center">
        <div className="font-bold text-xl mb-2">Dr. {doctor.doctor_name}</div>
        <p className="text-gray-700 text-base">Designation: {doctor.doctor_designation}</p>
        <p className="text-gray-700 text-base">
          Description: {doctor.doctor_description}
        </p>
        <p className="text-gray-700 text-base">
          Contact: {doctor.doctor_mobile}
        </p>
        <p className="text-gray-700 text-base">
          Email: {doctor.doctor_email}
        </p>
        <p className="text-gray-700 text-base">
          Indeed: <a href={doctor.doctor_indeed} target="_blank" rel="noopener noreferrer" className="text-blue-500">{doctor.doctor_indeed}</a>
        </p>
        <p className="text-gray-700 text-base">
          Facebook: <a href={doctor.doctor_facebook} target="_blank" rel="noopener noreferrer" className="text-blue-500">{doctor.doctor_facebook}</a>
        </p>
        <p className="text-gray-700 text-base">
          Instagram: <a href={doctor.doctor_insta} target="_blank" rel="noopener noreferrer" className="text-blue-500">{doctor.doctor_insta}</a>
        </p>
        <p className="text-gray-700 text-base">
          Twitter: <a href={doctor.doctor_twitter} target="_blank" rel="noopener noreferrer" className="text-blue-500">{doctor.doctor_twitter}</a>
        </p>
        <p className="text-gray-700 text-base">
          YouTube: <a href={doctor.doctor_youtube} target="_blank" rel="noopener noreferrer" className="text-blue-500">{doctor.doctor_youtube}</a>
        </p>
      </div>
      <div className="px-6 pt-4 pb-2 text-center">
        <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#cardiology</span>
        <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#heart</span>
        <span className="inline-block bg-gray-200 rounded-full px-3 py-1 text-sm font-semibold text-gray-700 mr-2 mb-2">#healthcare</span>
      </div>
    </div>
  );
}

export default DoctorProfileCard;
