/* eslint-disable */
import Api from "./Api";
export default {

  async SaveArticle(payload) {
    const response = await Api().post(`api/dashboard/articles`,payload);
    return response;
  },

  async getAllArticle(payload) {
    const response = await Api().get(`api/dashboard/articles?search=${payload}`);
    return response;
  },
  
  async getSingleArticle(payload) {
    const response = await Api().get(`api/dashboard/articles/${payload}`);
    return response;
  },

  async setArticleStatus(payload) {
    const response = await Api().post(`api/dashboard/articles/status`, payload);
    return response;
  },

  async deleteArticle(payload) {
    const response = await Api().post(`api/dashboard/articles/remove`, payload);
    return response;
  },

};
