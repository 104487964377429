import React, { useEffect, useState } from 'react'
import { FallingLinesLoader } from '../../../../components/spinners/Spinner'
import Table from '../../../../components/tables/table'
import Breadcrumb from '../../../../components/Breadcrumb'
import ConfirmationModal from '../../../../components/ConfirmationModal'
import SettingServices from '../../../../ApiServices/SettingServices'
import { toast } from 'react-toast'
import { setting_columns } from '../../../../components/tables/tableheader'
import { getAllowedActions, checkPermissionsAndRedirect } from "../../../../helper/commonHelper";
import { Link, useNavigate } from "react-router-dom";

const Setting = () => {
  const pages = [{ title: "Setting List", href: "/sys-config", module_id: 40 }];
  const [isLoading, setIsLoading] = useState(false)
  const [settingList, setSettingList] = useState([])
  const [confirmationModal, setConfirmationModal] = useState({
    status: false,
    setting_id: null,
  });
  const navigate = useNavigate();
  const [allowedActions, setAllowedActions] = useState([]);
  useEffect(() => {
    const permissions = JSON.parse(localStorage.getItem("user_permissions"));
    if (permissions && window.location.pathname) {
      const checkPermissionsAndRedirectFunc = checkPermissionsAndRedirect(window.location.pathname, permissions, navigate);
      const actions = getAllowedActions(permissions, window.location.pathname);
      setAllowedActions(actions);
    }
  }, []);

  useEffect(() => {
    getAllSettings()
  }, [])

  const getAllSettings = () => {
    setIsLoading(true)
    SettingServices.getAllSetting(3).then((res) => {
      const { data, status } = res;
      if (status) {
        console.log("firstdata", data.data)
        setSettingList(data.data)
      }
      setIsLoading(false)
    }).catch((error) => {
      console.log(error)

    });
  }

  const onStatusUpdate = (setting_id, setting_is_active) => {
    let payload = {
      table_name: "m_settings",
      table_field: "setting_id",
      table_field_val: setting_id,
      table_status_field: "setting_is_active",
      table_status_val: setting_is_active ? false : true,
      table_text: "Setting",
      deleted_by_key: "setting_updated_by",
      delete_active_txt: setting_is_active ? " Deactivated" : " Activated"
    }
    SettingServices.removeMaster(payload).then((res) => {
      const { data, status, message } = res.data
      if (status) {
        getAllSettings()
        toast.success(message);
      } else {
        toast.error(message)
      }
    })
  }
  const onDeleteOpen = (setting_id, setting_is_active) => {
    console.log("setting_id", setting_id, setting_is_active)
    setConfirmationModal({ setting_id, status: true, setting_is_active });
  }
  const onDeleteItem = (setting_id) => {

    SettingServices.deleteSetting({ setting_id })
      .then((response) => {
        const { data, status } = response;
        console.log("response Prod=>", response)
        if (data.status) {
          setIsLoading(true)
          toast.success(data.message)
          setConfirmationModal({ setting_id: null, status: false });
          getAllSettings()
          navigate("/sys-config");
        } else {
          toast.error(data.message)
        }
        setIsLoading(false)
      })
      .catch((error) => {
        // const msg = error.response.data.message;
        // toast.error(msg)
        toast.error("Sorry Fatal Error!")
        setIsLoading(false)
      });
  }
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <ConfirmationModal
        open={confirmationModal.status}
        setOpen={setConfirmationModal}
        onDelete={() => onDeleteItem(confirmationModal.setting_id)}
      />

      <Breadcrumb pages={pages} />
      <div className="">
        <h1 className="text-xl font-semibold text-gray-900">Setting</h1>
        <p className="mt-2 text-sm text-gray-700">A list of all the Settings.</p>
      </div>
      <div className="mt-4 flex">
        {allowedActions.filter((data) => data.permission_id == 64)?.length > 0 && settingList?.length<=0 ? (
          <Link
            to={"/add-sys-config"}
            type="button"
            className="inline-flex items-center justify-center rounded-md bbt bg-[#0172fd] px-4 py-2 text-sm font-medium text-white shadow-sm hffff  ml-auto"
          >
            Add Setting
          </Link>
        ) : null}
      </div>

      {isLoading ? (
        <FallingLinesLoader />
      ) : (
        <>
      
          <Table
            columns={setting_columns({ onDeleteOpen, onStatusUpdate, allowedActions })}
            data={settingList ? settingList : []}
            is_toggle={false}
          />
        </>
      )}
    </div>
  )
}

export default Setting