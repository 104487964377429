/* eslint-disable */
import Api from "./Api";
export default {

  async SaveTestimonial(payload) {
    const response = await Api().post(`api/dashboard/testimonial`,payload);
    return response;
  },

  async getAllTestimonial(payload) {
    const response = await Api().get(`api/dashboard/testimonial?search=${payload}`);
    return response;
  },
  
  async getSingleTestimonial(payload) {
    const response = await Api().get(`api/dashboard/testimonial/${payload}`);
    return response;
  },

  async setTestimonialStatus(payload) {
    const response = await Api().post(`api/dashboard/testimonial/status`, payload);
    return response;
  },

  async deleteTestimonial(payload) {
    const response = await Api().post(`api/dashboard/testimonial/remove`, payload);
    return response;
  },

};
